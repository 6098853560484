<template>
  <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
  <validation-observer ref="setOfCriteriaSaveModalRef">
    <b-modal id="evaluateCommonSaveModal" body-class="position-static" size="xl" centered
      :title="isCreated ? 'Phiếu khảo sát/đánh giá' : 'Cập nhật phiếu khảo sát/đánh giá'" no-close-on-backdrop
      @show="onShow" @hide="onHide" hideFooter>
      <div class="font-weight-bold">
        <b-row class="mx-2 my-2" v-if="employeeEvaluate.objectGroupEvaluateCriterias">
          <div class="font-weight-bold">Thực hiện trả lời các câu hỏi dưới đây: </div>
        </b-row>
        <div v-if="employeeEvaluate.objectGroupEvaluateCriterias">
          <div v-for="(item, index) in employeeEvaluate.objectGroupEvaluateCriterias" :key="index" class="mx-3">
            <b-row v-if="item.criteriaType === CRITERIA_TYPES[0].value">
              <b-col cols="12" md="12">
                <b-form-group :label-for="item.name">
                  <template v-slot:label>
                    {{ (index + 1) + ". " + item.name }} <span class="text-danger">*</span>
                  </template>
                  <vue-slider v-model="item.point" direction="ltr" :max="item.maxPoint" :min="1" style="width: 50%;"
                    class="mx-1 my-2" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="item.criteriaType === CRITERIA_TYPES[1].value && item.multipleSelect != MULTIPLE_CRITERIA_OPTION_TYPES[1].value">
              <b-col cols="12" md="12">
                <b-form-group :label-for="item.name">
                  <template v-slot:label>
                    {{ (index + 1) + ". " + item.name }} <span class="text-danger" v-if="item.required">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Câu hỏi này"
                    :rules="(item.required ? 'required' : '')">
                    <b-form-radio-group :id="'skin-radio-group_' + index" v-model="item.evaluationCriteriaOptionId[0]"
                      :name="'skin_' + index" :options="item.evaluationCriteriaOptions"
                      class="custom-control-primary pl-2" :state="getElementState(errors)"
                      @input="checkDisabledSubmitButton" />
                    <small class="text-danger mx-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="item.criteriaType === CRITERIA_TYPES[1].value && item.multipleSelect === MULTIPLE_CRITERIA_OPTION_TYPES[1].value">
              <b-col cols="12" md="12">
                <b-form-group :label-for="item.name">
                  <template v-slot:label>
                    {{ (index + 1) + ". " + item.name }} <span class="text-danger" v-if="item.required">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Câu hỏi này"
                    :rules="(item.required ? 'required' : '')">
                    <b-form-checkbox-group :id="'skin2-radio-group_' + index" v-model="item.evaluationCriteriaOptionId"
                      class="custom-control-primary pl-2" :options="item.evaluationCriteriaOptions"
                      :state="getElementState(errors)" @input="checkDisabledSubmitButton" />
                    <small class="text-danger mx-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="item.criteriaType === CRITERIA_TYPES[2].value">
              <b-col cols="12" md="12">
                <b-form-group :label-for="item.name">
                  <template v-slot:label>
                    {{ (index + 1) + '. ' + item.name }} <span class="text-danger" v-if="item.required">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Câu hỏi này"
                    :rules="(item.required ? 'required|' : '') + 'max:1000'">
                    <b-form-textarea :id="'question_' + index" placeholder="Nhập câu trả lời" rows="3"
                      v-model="item.content" :state="getElementState(errors)" class="mx-1 mt-2"
                      @input="checkDisabledSubmitButton" />
                    <small class="text-danger mx-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12" md="12">
              <div class="text-center my-2"><b>Không có câu hỏi khảo sát/đánh giá nào!</b></div>
            </b-col>
          </b-row>
        </div>
        <template v-if="employeeEvaluate.objectGroupEvaluateCriterias">
          <div class="w-100 d-flex justify-content-end my-1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
              :disabled="isDisabledSubmitButton" @click="onSave('hide')">
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Gửi đánh giá
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
              @click="$bvModal.hide('evaluateCommonSaveModal')">
              <span class="text-right"> <feather-icon icon="XIcon" /> Hủy </span>
            </b-button>
          </div>
        </template>
      </div>
    </b-modal>
  </validation-observer>
</b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BCardHeader,
  BCardTitle,
  BFormRadioGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
import { CRITERIA_TYPES, MULTIPLE_CRITERIA_OPTION_TYPES, REQUIRED } from '@/const/type'


export default {
  name: 'SetOfCriteriaSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    VueSlider,
    BCardHeader,
    BCardTitle,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    setOfCriteriaObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isSetOfCriteriaSaveLoading: false,
      required,
      isCheckCriterias: true,
      isCheckCriteriaTypeSliders: true,
      isLoading: false,
      isDisabledSubmitButton: false,
      CRITERIA_TYPES,
      MULTIPLE_CRITERIA_OPTION_TYPES,
      REQUIRED,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      employeeEvaluate: 'employeeEvaluate/employeeEvaluate'
    }),
    isCreated() {
      return this.employeeEvaluate.id === null
    },
  },
  methods: {
    ...mapActions({
      createEmployeeEvaluate: 'employeeEvaluate/createEmployeeEvaluate',
      updateEmployeeEvaluate: 'employeeEvaluate/updateEmployeeEvaluate',
      getEmployeeEvaluate: 'employeeEvaluate/getEmployeeEvaluate'
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isSetOfCriteriaSaveLoading = true
      await this.getEmployeeEvaluate({
        accountId: this.user.accountId, organizationId: this.user.orgId, setOfCriteriaObjectId: this.setOfCriteriaObject.id
      })
      this.isDisabledSubmitButton = false
      for (let i = 0; i < this.employeeEvaluate.objectGroupEvaluateCriterias.length; i++) {
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[0].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].point === 0) {
          this.isDisabledSubmitButton = true
          break;
        }
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[1].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].required === REQUIRED[1].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].evaluationCriteriaOptionId.length === 0) {
          this.isDisabledSubmitButton = true
          break;
        }
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[2].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].required === REQUIRED[1].value && (this.employeeEvaluate.objectGroupEvaluateCriterias[i].content === '' || this.employeeEvaluate.objectGroupEvaluateCriterias[i].content === null)) {
          this.isDisabledSubmitButton = true
          break;
        }
      }

      //Check show point for each criteria option
      if(this.setOfCriteriaObject.setOfEvaluateLevelId && this.setOfCriteriaObject.setOfEvaluateLevelId > 0) {
        for (let i = 0; i < this.employeeEvaluate.objectGroupEvaluateCriterias.length; i++) {
          if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[1].value) {
            this.employeeEvaluate.objectGroupEvaluateCriterias[i].evaluationCriteriaOptions.forEach(option => {
              option.text = option.text + " (" + option.point + " điểm)"
            })
          }
        }
      }
      this.isSetOfCriteriaSaveLoading = false
    },
    onHide() {
      this.$refs.setOfCriteriaSaveModalRef.reset()
      if(this.employeeEvaluate.id === null) {
        for (let i = 0; i < this.employeeEvaluate.objectGroupEvaluateCriterias.length; i++) {
          if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[1].value) {
            this.employeeEvaluate.objectGroupEvaluateCriterias[i].evaluationCriteriaOptionId = []
          }
        }
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .setOfCriteriaSaveModalRef
        .validate()
      if (valid) {
        this.isSetOfCriteriaSaveLoading = true
        try {
          const response = this.isCreated
            ? await this.createEmployeeEvaluate(this.employeeEvaluate)
            : await this.updateEmployeeEvaluate(this.employeeEvaluate)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('evaluateCommonSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isSetOfCriteriaSaveLoading = false
        }
      }
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    checkDisabledSubmitButton() {
      this.isDisabledSubmitButton = false
      for (let i = 0; i < this.employeeEvaluate.objectGroupEvaluateCriterias.length; i++) {
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[0].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].point === 0) {
          this.isDisabledSubmitButton = true
          break;
        }
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[1].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].required === REQUIRED[1].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].evaluationCriteriaOptionId.length === 0) {
          this.isDisabledSubmitButton = true
          break;
        }
        if (this.employeeEvaluate.objectGroupEvaluateCriterias[i].criteriaType === CRITERIA_TYPES[2].value && this.employeeEvaluate.objectGroupEvaluateCriterias[i].required === REQUIRED[1].value && (this.employeeEvaluate.objectGroupEvaluateCriterias[i].content === '' || this.employeeEvaluate.objectGroupEvaluateCriterias[i].content === null)) {
          this.isDisabledSubmitButton = true
          break;
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  label {
    font-size: 1rem;
    color: unset !important;
  }
}

#evaluateCreditClassSaveModal .vue-slider-handle-end {
  display: none;
  pointer-events: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>
